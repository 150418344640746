.blob {
  background-image: url(../assets/raph.webp);
  width: 20rem;
  height: 20rem;
  background-size: cover;
  background-position: center center;
  margin: 20px;
  box-shadow: 0 5px 5px 5px rgba(31, 41, 55, 0.9);
  animation: animate 5s ease-in-out infinite;
  transition: all 1s ease-in-out;
}
@keyframes animate {
  0%,
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
}
@media screen and (min-width: 480px) {
  .blob {
    width: 28rem;
    height: 28rem;
  }
}
